import produce from "immer";
import {
  ALERT_MEESAGE,
  SET_COUNTRIES,
  SET_PACKAGES,
  SET_BRANDS,
  SET_PAYMENT_PROVIDERS,
  SET_GOVENRATES_AND_DISTRICTS,
  SET_ALL_PREFERENCES,
  SET_PACKAGE_LOADER,
  SET_CUSTOM_PACKAGE,
  SET_CONFIG,
  SET_STORES,
  SET_ALLERGIES_SELECTION,
  SET_BUNDLE_DATA,
  SET_BUNDLE_FILTER,
  SET_BUNDLE_LOADER,
  SET_DRIVERS,
} from "app/utils/constants/common";
import { OriginalDriverInterface } from "app/types";

interface CommonState {
  message: string;
  variant: string;
  countries: any[];
  countriesMapping: any;
  packageLoading: boolean;
  packages: any[];
  customPackages: any;
  packageNext: string;
  packagePrev: string;
  packagesMappping: any;
  brands: any[];
  brandsMappping: any;
  paymentProviders: any[];
  paymentProvidersMapping: any;
  govenratesAndDistricts: any[];
  preferences: any[];
  configurations: any;
  stores: any;
  drivers: OriginalDriverInterface[];
  storesMapping: any;
  allergies: any;
  bundleData: any;
  bundleNextToken: any;
  bundlePrevToken: any;
  bundleFilters: any;
  bundleIncluded: any;
  bundlerLoader: boolean;
}

const initialState: CommonState = {
  message: "",
  variant: "",
  countries: [],
  countriesMapping: {},
  packageLoading: false,
  packages: [],
  customPackages: null,
  packageNext: null,
  packagePrev: null,
  packagesMappping: {},
  brands: [],
  brandsMappping: {},
  paymentProviders: [],
  paymentProvidersMapping: {},
  govenratesAndDistricts: [],
  preferences: [],
  // config wll be a object that contains Countries, and values of config per application.
  configurations: null,
  stores: null,
  drivers: [],
  storesMapping: {},
  allergies: null,

  bundleData: null,
  bundleNextToken: null,
  bundlePrevToken: null,
  bundleFilters: null,
  bundleIncluded: null,
  bundlerLoader: false,
};

export default produce((draft, action) => {
  switch (action.type) {
    case ALERT_MEESAGE:
      draft.message = action.payload.message;
      draft.variant = action.payload.variant;
      break;
    case SET_PACKAGE_LOADER:
      draft.packageLoading = action.payload;
      break;
    case SET_COUNTRIES:
      draft.countries = action.payload;
      draft.countriesMapping =
        action.payload?.length > 0
          ? Object.fromEntries(
              action.payload.map((item: any) => [item.id, item])
            )
          : {};
      break;
    case SET_PACKAGES:
      draft.packages = action.payload;
      draft.packagesMappping =
        action.payload?.length > 0
          ? Object.fromEntries(
              action.payload.map((item: any) => [item.id, item])
            )
          : {};
      break;
    case SET_CUSTOM_PACKAGE:
      draft.customPackages = action.payload.data;
      draft.packageNext = action.payload.nextToken;
      draft.packagePrev = action.payload.prevToken;
      break;
    case SET_BRANDS:
      draft.brands = action.payload;
      draft.brandsMappping =
        action.payload?.length > 0
          ? Object.fromEntries(
              action.payload.map((item: any) => [item.id, item])
            )
          : {};
      break;
    case SET_GOVENRATES_AND_DISTRICTS:
      draft.govenratesAndDistricts = action.payload;
      break;
    case SET_PAYMENT_PROVIDERS:
      draft.paymentProviders = action.payload;
      draft.paymentProvidersMapping =
        action.payload?.length > 0
          ? Object.fromEntries(
              action.payload.map((item: any) => [item.id, item])
            )
          : {};
      break;
    case SET_ALL_PREFERENCES:
      draft.preferences = action.payload;
      break;
    case SET_STORES:
      draft.stores = action.payload;
      draft.storesMapping =
        action.payload?.length > 0
          ? Object.fromEntries(
              action.payload.map((item: any) => [item.id, item])
            )
          : {};
      break;
    case SET_DRIVERS:
      draft.drivers = action.payload;
      break;
    case SET_CONFIG:
      draft.configurations = action.payload;
      break;
    case SET_ALLERGIES_SELECTION:
      draft.allergies = action.payload;
      break;
    case SET_BUNDLE_DATA:
      return {
        ...draft,
        bundleData: action.payload.bundleData,
        bundleNextToken: action.payload.bundleNextToken,
        bundlePrevToken: action.payload.bundlePrevToken,
        bundleIncluded: action.payload.bundleIncluded,
      };
    case SET_BUNDLE_FILTER:
      return {
        ...draft,
        bundleFilters: action.payload,
      };
    case SET_BUNDLE_LOADER:
      return {
        ...draft,
        bundlerLoader: action.payload,
      };
    default:
      return draft;
  }
}, initialState);
