import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery, getActiveConfig } from "app/utils/constants/common";
import {
  PlannedDeliveriesList,
  PlannedDeliveryDriver,
  PlannedDeliveryDriverLocal,
  PlannedDeliveryTask,
  UnassignedDeliveryLocal,
} from "app/types/distribution";
import { RootState } from "../reducers/hooks";
import {
  assignPlannedDeliveriesApi,
  getPlannedDeliveries,
  getPlannedDeliveriesById,
  getUnassignedDeliveriesApi,
  reAssignPlannedDeliveriesApi,
} from "app/services/plannedDeliveries";
import {
  AssignPlannedDeliveriesPayload,
  Paging,
  ReAssignPlannedDeliveriesPayload,
} from "app/types";
import format from "date-fns/format";

interface PlannedDeliveriesFilters {
  shiftIds: number;
  driverId: number;
}
interface InitialState {
  plannedDeliveries: {
    list: PlannedDeliveriesList[];
    paging: Paging;
  };

  filters: PlannedDeliveriesFilters | {};
  unassignedDeliveries: {
    list: UnassignedDeliveryLocal[];
    paging: Paging;
  };

  assignedPlannedDeliveriesLoading: boolean;
  unAssignedTaskLoading: boolean;
}

const initialState: InitialState = {
  plannedDeliveries: {
    list: [],
    paging: {
      currentPage: 1,
      pageSize: 20,
      totalRecords: 0,
    },
  },

  assignedPlannedDeliveriesLoading: false,
  unAssignedTaskLoading: false,
  unassignedDeliveries: {
    list: [],
    paging: {
      currentPage: 1,
      pageSize: 20,
      totalRecords: 0,
    },
  },
  filters: null,
};

export const plannedDeliveriesSlice = createSlice({
  name: "plannedDeliveries",
  initialState,
  reducers: {
    setPlannedDeliveriesList: (
      state,
      action: PayloadAction<{
        list: PlannedDeliveriesList[];
        page: Paging;
      }>
    ) => {
      state.plannedDeliveries = {
        list: action.payload.list,
        paging: action.payload.page,
      };
    },
    setAssignedDeliveriesLoading: (state, action: PayloadAction<boolean>) => {
      state.assignedPlannedDeliveriesLoading = action.payload;
    },
    setUnAssignedTaskLoading: (state, action: PayloadAction<boolean>) => {
      state.unAssignedTaskLoading = action.payload;
    },
    setPlannedDeliveriesFilters: (
      state,
      action: PayloadAction<PlannedDeliveriesFilters | {}>
    ) => {
      state.filters = action.payload;
    },
    setUnassignedDeliveriesList: (
      state,
      action: PayloadAction<{
        list: UnassignedDeliveryLocal[];
        page: Paging;
      }>
    ) => {
      state.unassignedDeliveries = {
        list: action.payload.list,
        paging: action.payload.page,
      };
    },
  },
});

export const {
  setPlannedDeliveriesList,
  setUnassignedDeliveriesList,
  setPlannedDeliveriesFilters,
  setAssignedDeliveriesLoading,
  setUnAssignedTaskLoading,
} = plannedDeliveriesSlice.actions;

export default plannedDeliveriesSlice.reducer;

type GetPlannedDeliveriesActionParams = {
  deliveryDate?: string;
  page?: number;
  pageSize?: number;
};

export const getPlannedDeliveriesAction = ({
  deliveryDate,
  page,
  pageSize,
}: GetPlannedDeliveriesActionParams = {}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { activeLocale } = getActiveConfig();
      const { filters, plannedDeliveries } = getState().plannedDeliveries;
      const paginationState = plannedDeliveries.paging;
      const countryId = activeLocale.CountryId;
      const filtersState = filters as PlannedDeliveriesFilters;

      let driverId: number | undefined;
      let shiftId: number | undefined;

      if (filtersState?.driverId) {
        driverId = filtersState.driverId;
      }

      if (filtersState?.shiftIds) {
        shiftId = filtersState.shiftIds;
      }

      const filter = {
        deliveryDate: deliveryDate || format(new Date(), "yyyy-MM-dd"),
        page: page || paginationState.currentPage,
        pageSize: pageSize || paginationState.pageSize,
        ...(driverId && { driverId }),
        ...(shiftId && { shiftId }), // Only add shiftId if it exists
      };

      const query = createQuery({
        filter: filter,
      });

      dispatch(setAssignedDeliveriesLoading(true));
      const { data } = await getPlannedDeliveries({
        countryId,
        query: `?${query}`,
      });

      const drivers = data.included.drivers || [];
      const list = data.data || [];
      const fleets = data.included.fleets || [];
      const deliveryShifts = data.included.deliveryShifts || [];
      const paging = data.paging;
      dispatch(setAssignedDeliveriesLoading(false));
      // Create a mapping of driver IDs
      const driverMap = drivers.reduce((map, driver) => {
        map[driver.id] = driver;
        return map;
      }, {} as Record<number, PlannedDeliveryDriver>);

      // Enhance planned deliveries with included data
      const plannedDeliveriesWithDrivers = list.map((delivery) => {
        const driver = driverMap[delivery.driverId];
        const updatedDriver: PlannedDeliveryDriverLocal = {
          ...driver,
        };

        // We take the first planned assignment for now
        const plannedAssignment = driver.plannedAssignments?.[0];
        let assignment = null;
        if (plannedAssignment) {
          const fleet = fleets.find(
            (fleet) => fleet.id === plannedAssignment.fleetId
          );
          const shift = deliveryShifts.find(
            (shift) => shift.id === plannedAssignment.shiftId
          );
          assignment = {
            fleet: fleet,
            shift: shift,
          };

          // Add the assignment to the driver
          updatedDriver.assignment = assignment;
        }

        return {
          ...delivery,
          driver: updatedDriver,
        };
      });
      // Dispatch the action to set the planned deliveries list
      dispatch(
        setPlannedDeliveriesList({
          list: plannedDeliveriesWithDrivers,
          page: paging,
        })
      );
    } catch (err) {
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};

interface GetPlannedDeliveriesByIdActionParams {
  id: string;
  successCallback?: (data: PlannedDeliveryTask[]) => void;
}

export const getPlannedDeliveriesByIdAction = ({
  id,
  successCallback,
}: GetPlannedDeliveriesByIdActionParams) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { activeLocale } = getActiveConfig();
      const countryId = activeLocale.CountryId;
      const { data } = await getPlannedDeliveriesById({
        countryId,
        id,
      });

      const deliveryTask = data.data;
      const delieveryShift = data.included.deliveryShifts;
      const zones = data.included.zones;

      // append zones and delivery shifts to the delivery task
      const updatedDeliveryTask = deliveryTask.map((task) => {
        const zone = zones.find((zone) => zone.id === task.zoneId);
        const shift = delieveryShift.find((shift) => shift.id === task.shiftId);

        return {
          ...task,
          zone,
          shift,
        };
      });
      successCallback(updatedDeliveryTask);
      return data;
    } catch (err) {
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};

interface GetUnassignedDeliveriesActionParams {
  deliveryDate?: string;
  page?: number;
  pageSize?: number;
}

export const getUnassignedDeliveriesAction = ({
  deliveryDate,
  page,
  pageSize,
}: GetUnassignedDeliveriesActionParams) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { activeLocale } = getActiveConfig();
      const countryId = activeLocale.CountryId;
      const { unassignedDeliveries, filters } = getState().plannedDeliveries;
      const paginationState = unassignedDeliveries.paging;
      const filtersState = filters as PlannedDeliveriesFilters;

      let shiftId: number | undefined;
      if (filtersState?.shiftIds) {
        shiftId = filtersState.shiftIds;
      }
      const filter = {
        deliveryDate: deliveryDate || format(new Date(), "yyyy-MM-dd"),
        page: page || paginationState.currentPage,
        pageSize: pageSize || paginationState.pageSize,
        ...(shiftId && { shiftId }),
      };
      const query = createQuery({
        filter: filter,
      });

      dispatch(setUnAssignedTaskLoading(true));
      const { data } = await getUnassignedDeliveriesApi({
        countryId,
        query: `?${query}`,
      });
      dispatch(setUnAssignedTaskLoading(false));
      const dataList = data.data;
      const zones = data.included.zones;
      const deliveryShifts = data.included.deliveryShifts;
      const updatedUnassignedDeliveries = dataList.map((unassignedDelivery) => {
        const zone = zones.find(
          (zone) => zone.id === unassignedDelivery.zoneId
        );
        const shift = deliveryShifts.find(
          (shift) => shift.id === unassignedDelivery.shiftId
        );
        return {
          ...unassignedDelivery,
          zone,
          shift,
        };
      });

      dispatch(
        setUnassignedDeliveriesList({
          list: updatedUnassignedDeliveries,
          page: data.paging,
        })
      );

      return updatedUnassignedDeliveries;
    } catch (err) {
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const assignPlannedDeliveriesAction = ({
  driverId,
  assigningTaskDetails,
}: AssignPlannedDeliveriesPayload) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data } = await assignPlannedDeliveriesApi({
        driverId,
        assigningTaskDetails,
      });
      dispatch(alertMessage(data.data.message, "success"));
      const { unassignedDeliveries } = getState().plannedDeliveries;
      const updatedList = unassignedDeliveries.list.filter(
        (delivery) =>
          delivery.id !== assigningTaskDetails[0]?.unAssignedDeliveryId
      );
      dispatch(
        setUnassignedDeliveriesList({
          list: updatedList,
          page: unassignedDeliveries.paging,
        })
      );
      return data; // Returning the response
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      throw error;
    }
  };
};

export const reAssignPlannedDeliveriesAction = ({
  driverId,
  unAssigningTaskDetails,
}: ReAssignPlannedDeliveriesPayload) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { data } = await reAssignPlannedDeliveriesApi({
        driverId,
        unAssigningTaskDetails,
      });
      dispatch(alertMessage(data.data.message, "success"));
      return data; // Returning the response
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      throw error;
    }
  };
};
