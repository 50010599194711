import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery, getActiveConfig } from "app/utils/constants/common";
import { DistributionDeliveriesInterface, DistributionDriver } from "app/types";
import { getDeliveryTasks } from "app/services/distribution";

interface InitialState {
  distributionDeliveriesList: DistributionDeliveriesInterface[];
}

const initialState: InitialState = {
  distributionDeliveriesList: [],
};

export const DistributionDeliveriesSlice = createSlice({
  name: "distributionDeliveries",
  initialState,
  reducers: {
    setDistributionDeliveriesList: (
      state,
      action: PayloadAction<DistributionDeliveriesInterface[]>
    ) => {
      state.distributionDeliveriesList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDistributionDeliveriesList } =
  DistributionDeliveriesSlice.actions;

export default DistributionDeliveriesSlice.reducer;

export const getDistributionDeliveryTaskListAction =
  ({
    customerPhoneNumber = "",
    page,
    limit,
    onNextPage,
  }: {
    customerPhoneNumber: string;
    page: number;
    limit: number;
    onNextPage: (nextPage: boolean) => void;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      const { activeLocale } = getActiveConfig();
      const countryId = activeLocale.CountryId;

      const query =
        "?" +
        createQuery({
          filter: {
            customerPhoneNumber,
            page,
            limit,
            isStatusGroup: "false",
          },
        });
      const { data } = await getDeliveryTasks({ countryId, query });

      // Process the data to include driver names
      const tasks = (data.data as DistributionDeliveriesInterface[]) || [];
      const drivers = (data.included?.drivers as DistributionDriver[]) || [];
      const isLastPage = page > 1 && tasks.length === 0;

      // Map tasks with corresponding driver information
      const processedData = tasks.map((task) => {
        const driver = drivers.find((d) => d.id === task.driverId);
        return {
          ...task,
          driver: driver || null,
          driverName: driver ? driver.name : "",
        };
      });

      // if its not the last page, enable next page
      onNextPage(!isLastPage);

      dispatch(setDistributionDeliveriesList(processedData));
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
