import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";

import {
  CreateDistributionDriverPayload,
  DistributionDriver,
  DriverFleet,
  PaginationInterfaceLF,
} from "app/types";
import {
  createDistributionDriversApi,
  updateDistributionDriverById,
  getDistributionDriversApi,
  getDistributionDriversById,
  getDriversCredentialsById,
  getFleetsApi,
  getCapacityLimitsApi,
} from "app/services/distribution";
import { RootState } from "../reducers/hooks";
import { createQuery, getActiveConfig } from "app/utils/constants/common";
import { CapacityLimit } from "app/types/distribution";

interface DistributionDriverFilters {
  fleetId: number;
  zoneId: number;
  search: string;
}

interface InitialState {
  drivers: DistributionDriver[];
  driversPagination: PaginationInterfaceLF;
  driverListRowPerPage: number;
  filters: DistributionDriverFilters | {};
  fleets: DriverFleet[];
  loading: boolean;
  capacityLimits: CapacityLimit[];
}

const initialState: InitialState = {
  drivers: [],
  driversPagination: null,
  driverListRowPerPage: 20,
  filters: null,
  fleets: [],
  loading: false,
  capacityLimits: [],
};

export const distributionDriverSlice = createSlice({
  name: "distributionDriver",
  initialState,
  reducers: {
    setDistributionDrivers: (
      state,
      action: PayloadAction<{
        driver: DistributionDriver[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.drivers = action.payload.driver;
      state.driversPagination = action.payload.pagination;
    },

    setDriverFilters: (
      state,
      action: PayloadAction<DistributionDriverFilters | {}>
    ) => {
      state.filters = action.payload;
    },

    setDriverFleets: (state, action: PayloadAction<DriverFleet[]>) => {
      state.fleets = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setDriverListRowPerPage: (state, action: PayloadAction<number>) => {
      state.driverListRowPerPage = action.payload;
    },

    setCapacityLimits: (state, action: PayloadAction<CapacityLimit[]>) => {
      state.capacityLimits = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDistributionDrivers,
  setDriverFilters,
  setDriverFleets,
  setLoading,
  setDriverListRowPerPage,
  setCapacityLimits,
} = distributionDriverSlice.actions;

export default distributionDriverSlice.reducer;

export const getDistributionDriversAction = (
  { continutationToken } = {
    continutationToken: null,
  }
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { filters, driverListRowPerPage } = getState().distributionDriver;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            ...filters,
            limit: driverListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setLoading(true));
      const { data } = await getDistributionDriversApi(query);
      dispatch(setLoading(false));
      const drivers = data.data;
      const fleets = data.included.fleets;
      const zones = data.included.zones;
      const capacityLimits = data.included.capacityLimits;

      const pagination = data.pagination;
      // Update the drivers array to map the fleets and zones based on assignments
      const updatedDrivers = drivers.map((driver) => {
        // Map the assignments to find the corresponding fleet and zone names
        const mappedAssignments = driver.assignments.map((assignment) => {
          const fleet = fleets.find((f) => f.fleetId === assignment.fleetId);
          const zone = zones.find((z) => z.zoneId === assignment.zoneId);
          return {
            ...assignment,
            fleetName: fleet?.name || "-",
            zoneName: zone?.name || "-",
          };
        });

        const capacityLimit = capacityLimits.find(
          (cl) => cl.id === driver.capacityLimitId
        );

        return {
          ...driver,
          assignments: mappedAssignments,
          capacityLimit,
        };
      });

      // Dispatch the updated drivers to the state
      dispatch(
        setDistributionDrivers({
          driver: updatedDrivers,
          pagination: pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getFleetsAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await getFleetsApi();
      dispatch(setDriverFleets(data.data));
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getDriverByIdAction = (
  id: number,
  callback: (data: DistributionDriver | null, error?: string) => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await getDistributionDriversById(id);
      dispatch(setLoading(false));
      const capacityLimits = data.included.capacityLimits as CapacityLimit[];
      const driver = data.data as DistributionDriver;
      const capacityLimit = capacityLimits.find(
        (cl) => cl.id === driver.capacityLimitId
      );
      callback({ ...driver, capacityLimit });

      // callback(data.data as DistributionDriver);
    } catch (error) {
      const message = errorHandler(error);
      callback(null, message);
    }
  };
};

export const createDistributionDriverAction = (
  payload: CreateDistributionDriverPayload
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await createDistributionDriversApi(payload);
      dispatch(alertMessage("Driver created successfully", "success"));
      // Refresh the drivers list if new driver is created
      dispatch(getDistributionDriversAction());
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const updateDistributionDriverByIdAction = (
  id: number,
  payload: CreateDistributionDriverPayload
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await updateDistributionDriverById(id, payload);

      // Refresh the drivers list if new driver is created
      dispatch(getDistributionDriversAction());
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getDriverCredentialsByIdAction = (
  id: number,
  callback: (
    data: {
      identity: string;
      email: string;
    } | null,
    error?: string
  ) => void
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await getDriversCredentialsById(id);
      callback(data.data);
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getCapacityLimitsAction = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const { defaultStoreId } = getActiveConfig();
      const { data } = await getCapacityLimitsApi(defaultStoreId);
      dispatch(setCapacityLimits(data.data));
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};
